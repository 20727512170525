import * as React from 'react';
import { createSvgIcon } from '../utils/createSvgIcon';

export const WordColorIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="0 0 32 32" className={classes.svg}>
      <path
        d="M9.5 29H28.5C28.775 29 29 28.775 29 28.5V9H24.5C23.673 9 23 8.327 23 7.5V3H9.5C9.225 3 9 3.225 9 3.5V28.5C9 28.775 9.225 29 9.5 29Z"
        fill="white"
      />
      <path d="M28.293 8.00003L24 3.70703V7.50003C24 7.77503 24.225 8.00003 24.5 8.00003H28.293Z" fill="white" />
      <path
        opacity="0.64"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.56 7.854L24.146 2.439C23.8642 2.15891 23.4833 2.00117 23.086 2H9.5C8.673 2 8 2.673 8 3.5V28.5C8 29.327 8.673 30 9.5 30H28.5C29.327 30 30 29.327 30 28.5V8.914C30 8.514 29.844 8.137 29.56 7.854ZM24 3.707L28.293 8H24.5C24.2241 7.99945 24.0005 7.77591 24 7.5V3.707ZM9.5 29H28.5C28.775 29 29 28.775 29 28.5V9H24.5C23.673 9 23 8.327 23 7.5V3H9.5C9.225 3 9 3.225 9 3.5V28.5C9.00055 28.7759 9.22409 28.9995 9.5 29Z"
        fill="#605E5C"
      />
      <path d="M25.5 22H17V23H25.5C25.7761 23 26 22.7761 26 22.5C26 22.2239 25.7761 22 25.5 22Z" fill="#103F91" />
      <path d="M25.5 19H17V20H25.5C25.7761 20 26 19.7761 26 19.5C26 19.2239 25.7761 19 25.5 19Z" fill="#185ABD" />
      <path d="M25.5 16H17V17H25.5C25.7761 17 26 16.7761 26 16.5C26 16.2239 25.7761 16 25.5 16Z" fill="#2B7CD3" />
      <path d="M25.5 13H17V14H25.5C25.7761 14 26 13.7761 26 13.5C26 13.2239 25.7761 13 25.5 13Z" fill="#41A5EE" />
      <path
        d="M3.5 25H14.5C15.3284 25 16 24.3284 16 23.5V12.5C16 11.6716 15.3284 11 14.5 11H3.5C2.67157 11 2 11.6716 2 12.5V23.5C2 24.3284 2.67157 25 3.5 25Z"
        fill="#185ABD"
      />
      <path
        d="M7.215 20.73H7.237C7.246 20.615 8.37 15 8.37 15H9.676C9.676 15 10.816 20.353 10.851 20.72H10.868C10.883 20.468 11.812 15 11.812 15H13L11.538 22H10.149C10.149 22 8.996 16.47 8.987 16.372H8.969C8.959 16.485 7.886 22 7.886 22H6.476L5 15H6.21C6.21 15 7.21 20.607 7.215 20.73Z"
        fill="#F9F7F7"
      />
    </svg>
  ),
  displayName: 'WordColorIcon',
});
